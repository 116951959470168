@font-face {
  font-family: 'Modernist';
  src: url('assets/fonts/modernist.woff2') format('woff2'),
    url('assets/fonts/modernist.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  background-color: #101010;
  font-family: 'Modernist', sans-serif;
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

:root {
  --primary-color: #1E90FF;
  --secondary-color: #FFA500;
  --tertiary-color: #2f00ff;
}

/* all text to be Mordernist font */
h1,
h2,
h3,
h4,
p,
span {
  font-family: 'Modernist', sans-serif;
}


h1 {
  font-size: 6rem;
  color: white;
  font-weight: 700;
  text-align: left;
  margin: 0;
  line-height: 1.1;
}

h2 {
  font-size: 1.8rem;
  color: white;
  font-weight: 700;
  text-align: left;
  line-height: 1.2;
  margin: 0;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 4.5rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.2rem;
  }
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

span {
  font-size: inherit;
  color: var(--primary-color);
  text-align: left;
  transition: color 0.3s ease;
}

h4 {
  font-size: 1.2rem;
  color: white;
  font-weight: 600;
  text-align: left;
  margin: 0;
  line-height: 1.4;
}

@media (max-width: 768px) {
  h4 {
    font-size: 1.1rem;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
  border: 3px solid black;
}

::-webkit-scrollbar {
  width: 11px;
  height: 10px;
}